<template>
  <card :title="$t('Администрирование')">
    <el-tabs :tab-position="'top'" v-model="nowTab" @tab-click="tabChouse" v-if="nowTab">
      <el-tab-pane :label="tab.externalSystemName" :name="tab.externalSystemId+'_tab'" :tabdata="tab" v-for="(tab, index) in listtabs" :key="index"  >
        <the-table-page
          :titleTable="tab.externalSystemName"
          v-if="nowTab == tab.externalSystemId+'_tab'"
          :tableData="tableData"
          :load="load"
          fieldId="esbRouteId"
          :updateData="updateData"
          :fieldInfoList="fieldInfoList"
          :searchStart="searchStart"
          :sortData="sortData"
          :setSort="setSort"
          :deleteEl="deleteEl"
          :prefixEdit="`/adminmanager/${tab.externalSystemId}`"
          :prefixShow="`/adminmanager/${tab.externalSystemId}`"
          :linkCreate="`/adminmanager/${tab.externalSystemId}/create`"
          configName="adminmanger"
          :checkboxfields="['denySource', 'denyTarget']"
          :roleName="nameRoleEntity"
        ></the-table-page>
      </el-tab-pane>
    </el-tabs>
  </card>
</template>

  <script>
  
  import { Tabs, TabPane } from "element-ui";
    import TheTablePage from "../../TheTablePage.vue";
    import { ENTITY_NAME , LOAD_LIST_TABS, LOAD_TABS_DATA, SET_TAB_ADMIN_MANAGER, SET_SISTEM_ID_ADMIN_MANAGER} from "./store/type";

    import {
      SET_MAIN_PAGINATION,
      SET_MAIN_PAGINATION_PER_PAGE,
      SET_MAIN_PAGINATION_PAGE,
      SET_MAIN_SORT_ENTITY,
      SET_MAIN_SEARCH_ENTITY
    } from "../../../../src/store/type";

    import { READ, DELETE } from "../../../store/type";
    import { mapState } from "vuex";
    import NotificationSample from "src/pages/Dashboard/Components/NotificationSample";
    import {
      DEFAULT_PER_PAGE,
      TYPE_ERRORS,
      GET_MEMORY_PER_PAGE
    } from "../../../constant";
    import notify from "../../../helpers/notify/notify";
    import convertLangDataToTable from "../../../helpers/converters/convertLangDataToTable";
    import {NAME_OBJECT} from "../../../RoleConstanans"
    export default {
      components: {
        TheTablePage,
          ElTabs: Tabs,
    ElTabPane: TabPane,
      },
      props: {
        forseNoCreateLink: { type: Boolean, default: false }
      },
      data() {
        return {
          scheme: {},
          load: false,
         
        };
      },
      computed: {
        ...mapState({
          fieldInfoList: state => state.adminmanager.fieldInfoList,
          lang: state => state.common.lang,
          sortData: state => state.pagination.sortData,
          listtabs: state=>state.adminmanager.listtabs,
        }),
        nowTab:{
          get(){ 
            return this.$store.state.adminmanager.nowTab
          },
          set(val){ 
            this.$store.commit(SET_TAB_ADMIN_MANAGER, val)
          }
        },

        externalSystemId:{
          get(){ 
            return this.$store.state.adminmanager.externalSystemId
          },
          set(val){ 
            this.$store.commit(SET_SISTEM_ID_ADMIN_MANAGER, val)
          }
        },

        tableData() {
          let langNow = this.$store.state.common.lang;
          let dataNow = this.$store.state.adminmanager.data || [];
          if (dataNow && dataNow.length > 0) {
            dataNow = convertLangDataToTable(dataNow, langNow, "", false);
            return dataNow;
          }
        },
        nameRoleEntity(){
          return NAME_OBJECT.Esb
        },
      },
      watch: {
        lang() {
          this.initData();
        }
      },
      methods: {
       async tabChouse(event){
          let tabInfo = event.$attrs.tabdata
          this.externalSystemId =  tabInfo.externalSystemId
          this.initData()
        },
        getData() {
          this.load = true;
          this.$store
            .dispatch(ENTITY_NAME + READ, {
              self: this,
              systemId: this.externalSystemId
            })
            .then(res => {
              this.load = false;
            })
            .catch(err => {
              this.load = false;
              notify(
                this,
                this.$t("Ошибка получения данных"),
                TYPE_ERRORS.DANGER
              );
            });
        },
        updateData() {
          this.getData();
        },
        searchStart(search) {
          this.$store.commit(SET_MAIN_SEARCH_ENTITY, search);
          this.getData();
        },
        setSort(val) {
          this.$store.commit(SET_MAIN_SORT_ENTITY, val);
          this.getData();
        },
        deleteEl(id) {
          this.$store
            .dispatch(ENTITY_NAME + DELETE, {
              self: this,
              id: id
            })
            .then(res => {
              this.getData();
               if(res.errors && res.errors.length  == 0) {
                  notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS); 
              }
            });
        },
        initData(load=true) {
          this.$store.commit(ENTITY_NAME + READ, {
            data: [],
            fieldInfoList: []
          });
          this.$store.commit(SET_MAIN_SORT_ENTITY, {});
          this.$store.commit(SET_MAIN_SEARCH_ENTITY, "");
          this.$store.commit(
            SET_MAIN_PAGINATION_PER_PAGE,
            GET_MEMORY_PER_PAGE()
          );
          this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1);
          if(load) this.getData();
        }
      },
      async created() {
        this.initData(false);
        try {
         let res =  await this.$store.dispatch(LOAD_LIST_TABS, {self: this})
         if (res && res.object) {
          this.nowTab = (this.nowTab) ? this.nowTab : res.object[0].externalSystemId+'_tab'
          this.externalSystemId =  (this.externalSystemId) ? this.externalSystemId : res.object[0].externalSystemId
          this.initData()
         }
        } catch(err) {
          console.error(err)
        }
      }
    };
  </script>
</template>
